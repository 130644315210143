<template>
  <button
    ref="erpButton"
    :class="[
        useSyncfusionComponentStyle ? 'e-control' : '',
        useSyncfusionComponentStyle ? 'e-btn' : '',
        useSyncfusionComponentStyle ? 'e-lib' : '',
      ]"
    :disabled="isButtonDisable"
    v-bind="$attributes"
    v-on="$events"
    @click.prevent="onButtonClicked"
  >
    <slot/>
    <template v-if="visibleShortcutString && shortcutByComputed.key">({{ shortcutString }})</template>
  </button>
</template>

<script>
import commonMixin from "@/views/layout/mixin/commonMixin";
import overlayMixin from "@/views/layout/mixin/overlayMixin";

import GolfErpAPI from "@/api/v2/GolfErpAPI";

import {
  commonCodesGetCommonCodeByIdxAttrb,
} from "@/utils/commonCodes";
import {mapGetters, mapMutations} from "vuex";
import {addShortcut, keypressEventEqualsShortcut, removeShortcut, toShortcutString} from "@/utils/KeyboardUtil";

export default {
  name: "ErpButton",
  mixins: [commonMixin, overlayMixin],
  components: {},
  props: {
    buttonDiv: {
      // 버튼 구분
      // GET / SAVE / DELETE / PRINT / FILE / CLOSE
      // GET : 조회, 초기화, 변경이력, 팝업이 오픈되는 버튼 등등
      // SAVE : 저장, 추가(팝업 오픈이 아닌) 등등
      // DELETE : 삭제
      // PRINT : 인쇄
      // FILE : Excel
      // CLOSE : 닫기
      type: String,
      default: () => "GET",
    },
    isShortcutButton: {
      // 기본(GET/SAVE/DELETE/PRINT) 단축키 사용 여부
      type: Boolean,
      default: () => false,
    },
    isCustomShortcutButton: {
      // Custom 단축키 사용 여부
      type: Boolean,
      default: () => false,
    },
    ignore: {
      // 단축키 작동 무시 여부
      type: [Boolean],
      default: () => false,
    },
    useSyncfusionComponentStyle: {
      // Syncfusion 버튼 스타일 사용 여부
      type: Boolean,
      default: () => true,
    },
    visibleShortcutString: {
      // 버튼의 단축키 표시 여부
      // false면 단축키 글자 표시만 안되고 단축키 기능 작동은 됨
      type: Boolean,
      default: () => true,
    },
    shortcutKey: {
      // 단축키의 ID(같은 화면에서 중복 불가)
      // 예) "FrontCheckinVisit.shortcuts.lockerView"
      //     화면이름.shortcuts.버튼이름
      type: [String],
      default: () => null,
    },
    shortcut: {
      // 단축키 설정 (isCustomShortcutButton == true)
      //  {
      //    ctrlKey:  Boolean // Ctrl 키 사용 여부
      //    altKey:   Boolean // Alt 키 사용 여부
      //    shiftKey: Boolean // Shift 키 사용 여부
      //    key:      String  // 키 조합(src/utils/KeyboardUtil.js의 SHORTCUT_KEYS 참조)
      //  }
      type: Object,
      default: () => null,
    },
    menuId: {
      type: Number,
      default: () => null,
    },
  },
  data() {
    return {
      timeOutId: null,
    };
  },
  created() {
    this.isCustomShortcutButton && addShortcut(this.shortcutKey, this.shortcutByComputed);
    window.addEventListener("keydown", this.onWindowKeydown, true);
    window.addEventListener("keyup", this.onWindowKeyup);
  },
  beforeDestroy() {
    this.isCustomShortcutButton && removeShortcut(this.shortcutKey);
    window.removeEventListener("keydown", this.onWindowKeydown, true);
    window.removeEventListener("keyup", this.onWindowKeyup);
    this.timeOutId && clearTimeout(this.timeOutId);
  },
  // destroyed() {
  //   if (this.isCustomShortcutButton) {
  //     removeShortcut(this.shortcutKey);
  //   }
  //   window.removeEventListener("keydown", this.onWindowKeydown, true);
  //   window.removeEventListener("keyup", this.onWindowKeyup);
  // },
  computed: {
    ...mapGetters(["isWaitShortcut"]),
    ...mapGetters("dialog", ["activeDialog"]),
    $attributes() {
      return Object.assign({}, this.$attrs, {});
    },
    $events() {
      return Object.assign({}, this.$listeners, {});
    },
    shortcutString() {
      if (!this.isShortcutButton && !this.isCustomShortcutButton) {
        return null;
      }
      return toShortcutString(this.shortcutByComputed);
    },
    buttonDivByComputed() {
      return this.buttonDiv.toUpperCase();
    },
    shortcutByComputed() {
      const customShortcut = {
        ctrlKey: false,
        altKey: false,
        shiftKey: false,
        key: null,
        keyName: null,
      };

      if (this.isCustomShortcutButton && this.shortcut) {
        customShortcut.ctrlKey = this.shortcut.ctrlKey || false;
        customShortcut.altKey = this.shortcut.altKey || false;
        customShortcut.shiftKey = this.shortcut.shiftKey || false;
        customShortcut.key = this.shortcut.key || null;
        customShortcut.keyName = this.shortcut.keyName || null;
      } else if (this.isShortcutButton) {
        switch (this.buttonDiv) {
          case "GET":
            customShortcut.key = "F2";
            break;
          case "SAVE":
            customShortcut.key = "F6";
            break;
          case "DELETE":
            customShortcut.key = "F4";
            break;
          case "PRINT":
            customShortcut.key = "P";
            break;
        }
      }

      return {
        ctrlKey: customShortcut.ctrlKey,
        altKey: customShortcut.altKey,
        shiftKey: customShortcut.shiftKey,
        key: customShortcut.key,
        keyName: customShortcut.keyName,
      };
    },
    isButtonDisable() {
      const routerName = this.$route.name;
      // 권한이 필요없는 화면
      if (["login"].includes(routerName)) {
        return false;
      }

      return (
        this.buttonDiv === "CLOSE" ?
          false :
          this.commonMixinIsButtonDisableByMenuAuth((this.buttonDiv.toUpperCase() === "PRINT" ? "PRT" : this.buttonDiv.toUpperCase()) + "_AUTH", this.menuId ? this.menuId : null) ||
          (this.$attributes?.disabled ? this.$attributes?.disabled : false)
      );
    },
  },
  methods: {
    ...mapMutations(["SET_IS_WAIT_SHORTCUT"]),
    blur() {
      this.$refs["erpButton"].blur();
    },
    focus() {
      this.$refs["erpButton"].focus();
    },
    scrollIntoView(args) {
      this.$refs["erpButton"].scrollIntoView(args);
    },
    onWindowKeydown(event) {
      if (this.ignore || this.activeDialog || this.isWaitShortcut || (!this.isShortcutButton && !this.isCustomShortcutButton)) {
        return;
      }

      if (keypressEventEqualsShortcut(event, this.shortcutByComputed)) {
        this.SET_IS_WAIT_SHORTCUT(true);
        this.$refs["erpButton"].focus();

        // TODO overlay timing
        this.timeOutId = setTimeout(() => {
          if (!this.overlayMixinCheckIsApiOverlayOpened()) {
            this.$refs["erpButton"].click();
          }
        }, 300);
      }
    },
    onWindowKeyup() {
      this.SET_IS_WAIT_SHORTCUT(false);
    },
    async onButtonClicked() {
      if (this.buttonDiv === "CLOSE") {
        return;
      }
      await this.createProgramButtonUseHist();
    },
    async createProgramButtonUseHist() {
      const pgId = this.$route.fullPath;
      const menuAuthDivs = commonCodesGetCommonCodeByIdxAttrb("MENU_AUTH_DIV", 1, this.buttonDivByComputed);
      const buttonName =
        (this.$slots.default ?
          this.$slots.default.map(slot => (slot?.elm?.innerText ? slot.elm.innerText : slot.text ? slot.text : "").trim()).filter(item => item).join(",") :
          this.buttonDiv) || this.buttonDiv;
      const menuId = this.commonMixinGetMenuId({ menuId: this.menuId });

      const data = {
        pgId: pgId,
        menuAuthDiv: menuAuthDivs && menuAuthDivs.length > 0 ? menuAuthDivs[0].comCode : "",
        buttonName: buttonName,
        menuId: menuId,
      };

      await GolfErpAPI.createProgramButtonUseHist(data);
    },
  },
};
</script>